/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI
 * Daily Update API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "./configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
import type { RequestArgs } from "./base";
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  BaseAPI,
  RequiredError,
  operationServerMap,
} from "./base";

/**
 *
 * @export
 * @interface AddImagePrompt200Response
 */
export interface AddImagePrompt200Response {
  /**
   *
   * @type {string}
   * @memberof AddImagePrompt200Response
   */
  message?: string;
}
/**
 *
 * @export
 * @interface AddImagePromptRequest
 */
export interface AddImagePromptRequest {
  /**
   * The title of the image prompt.
   * @type {string}
   * @memberof AddImagePromptRequest
   */
  title: string;
  /**
   * The text description of the image prompt.
   * @type {string}
   * @memberof AddImagePromptRequest
   */
  text: string;
}
/**
 *
 * @export
 * @interface AddLearningTopic200Response
 */
export interface AddLearningTopic200Response {
  /**
   *
   * @type {string}
   * @memberof AddLearningTopic200Response
   */
  message?: string;
}
/**
 *
 * @export
 * @interface AddLearningTopicRequest
 */
export interface AddLearningTopicRequest {
  /**
   * The name of the learning topic to add.
   * @type {string}
   * @memberof AddLearningTopicRequest
   */
  topic: string;
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   *
   * @type {number}
   * @memberof ErrorResponse
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof ErrorResponse
   */
  message: string;
}
/**
 *
 * @export
 * @interface Image
 */
export interface Image {
  /**
   *
   * @type {string}
   * @memberof Image
   */
  image_date: string;
  /**
   *
   * @type {string}
   * @memberof Image
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Image
   */
  prompt: string;
  /**
   *
   * @type {string}
   * @memberof Image
   */
  title_key: string;
}
/**
 *
 * @export
 * @interface Learning
 */
export interface Learning {
  /**
   *
   * @type {string}
   * @memberof Learning
   */
  topic_date: string;
  /**
   *
   * @type {string}
   * @memberof Learning
   */
  topic: string;
  /**
   *
   * @type {string}
   * @memberof Learning
   */
  learning: string;
  /**
   *
   * @type {string}
   * @memberof Learning
   */
  recipe?: string;
}
/**
 *
 * @export
 * @interface News
 */
export interface News {
  /**
   *
   * @type {string}
   * @memberof News
   */
  news_date: string;
  /**
   *
   * @type {string}
   * @memberof News
   */
  news: string;
  /**
   *
   * @type {Array<string>}
   * @memberof News
   */
  articles: Array<string>;
}

/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a new image prompt
     * @param {AddImagePromptRequest} addImagePromptRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addImagePrompt: async (
      addImagePromptRequest: AddImagePromptRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'addImagePromptRequest' is not null or undefined
      assertParamExists(
        "addImagePrompt",
        "addImagePromptRequest",
        addImagePromptRequest
      );
      const localVarPath = `/iotd/prompts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addImagePromptRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get image of the day for given date in DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the image for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImage: async (
      searchDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/iotd`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (searchDate !== undefined) {
        localVarQueryParameter["search_date"] = searchDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Publish today\'s image summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setImage: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/iotd`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add a new image prompt
     * @param {AddImagePromptRequest} addImagePromptRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addImagePrompt(
      addImagePromptRequest: AddImagePromptRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AddImagePrompt200Response>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addImagePrompt(
        addImagePromptRequest,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ImageApi.addImagePrompt"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get image of the day for given date in DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the image for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getImage(
      searchDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getImage(
        searchDate,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ImageApi.getImage"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Publish today\'s image summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setImage(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setImage(
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["ImageApi.setImage"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ImageApiFp(configuration);
  return {
    /**
     *
     * @summary Add a new image prompt
     * @param {AddImagePromptRequest} addImagePromptRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addImagePrompt(
      addImagePromptRequest: AddImagePromptRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<AddImagePrompt200Response> {
      return localVarFp
        .addImagePrompt(addImagePromptRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get image of the day for given date in DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the image for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getImage(
      searchDate?: string,
      options?: AxiosRequestConfig
    ): AxiosPromise<Image> {
      return localVarFp
        .getImage(searchDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Publish today\'s image summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setImage(options?: AxiosRequestConfig): AxiosPromise<object> {
      return localVarFp
        .setImage(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
  /**
   *
   * @summary Add a new image prompt
   * @param {AddImagePromptRequest} addImagePromptRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageApi
   */
  public addImagePrompt(
    addImagePromptRequest: AddImagePromptRequest,
    options?: AxiosRequestConfig
  ) {
    return ImageApiFp(this.configuration)
      .addImagePrompt(addImagePromptRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get image of the day for given date in DB
   * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the image for. Defaults to latest in the table.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageApi
   */
  public getImage(searchDate?: string, options?: AxiosRequestConfig) {
    return ImageApiFp(this.configuration)
      .getImage(searchDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Publish today\'s image summary to DB
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImageApi
   */
  public setImage(options?: AxiosRequestConfig) {
    return ImageApiFp(this.configuration)
      .setImage(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * LearningApi - axios parameter creator
 * @export
 */
export const LearningApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a new learning topic
     * @param {AddLearningTopicRequest} addLearningTopicRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLearningTopic: async (
      addLearningTopicRequest: AddLearningTopicRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'addLearningTopicRequest' is not null or undefined
      assertParamExists(
        "addLearningTopic",
        "addLearningTopicRequest",
        addLearningTopicRequest
      );
      const localVarPath = `/learning/topics`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        addLearningTopicRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get learning of the day for given date from DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the learning for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLearning: async (
      searchDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/learning`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (searchDate !== undefined) {
        localVarQueryParameter["search_date"] = searchDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Publish today\'s learning summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setLearning: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/learning`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * LearningApi - functional programming interface
 * @export
 */
export const LearningApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = LearningApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Add a new learning topic
     * @param {AddLearningTopicRequest} addLearningTopicRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addLearningTopic(
      addLearningTopicRequest: AddLearningTopicRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<AddLearningTopic200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.addLearningTopic(
          addLearningTopicRequest,
          options
        );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LearningApi.addLearningTopic"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get learning of the day for given date from DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the learning for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLearning(
      searchDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Learning>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLearning(
        searchDate,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LearningApi.getLearning"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Publish today\'s learning summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setLearning(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setLearning(
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["LearningApi.setLearning"]?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * LearningApi - factory interface
 * @export
 */
export const LearningApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LearningApiFp(configuration);
  return {
    /**
     *
     * @summary Add a new learning topic
     * @param {AddLearningTopicRequest} addLearningTopicRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addLearningTopic(
      addLearningTopicRequest: AddLearningTopicRequest,
      options?: AxiosRequestConfig
    ): AxiosPromise<AddLearningTopic200Response> {
      return localVarFp
        .addLearningTopic(addLearningTopicRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get learning of the day for given date from DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the learning for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLearning(
      searchDate?: string,
      options?: AxiosRequestConfig
    ): AxiosPromise<Learning> {
      return localVarFp
        .getLearning(searchDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Publish today\'s learning summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setLearning(options?: AxiosRequestConfig): AxiosPromise<object> {
      return localVarFp
        .setLearning(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * LearningApi - object-oriented interface
 * @export
 * @class LearningApi
 * @extends {BaseAPI}
 */
export class LearningApi extends BaseAPI {
  /**
   *
   * @summary Add a new learning topic
   * @param {AddLearningTopicRequest} addLearningTopicRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningApi
   */
  public addLearningTopic(
    addLearningTopicRequest: AddLearningTopicRequest,
    options?: AxiosRequestConfig
  ) {
    return LearningApiFp(this.configuration)
      .addLearningTopic(addLearningTopicRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get learning of the day for given date from DB
   * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the learning for. Defaults to latest in the table.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningApi
   */
  public getLearning(searchDate?: string, options?: AxiosRequestConfig) {
    return LearningApiFp(this.configuration)
      .getLearning(searchDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Publish today\'s learning summary to DB
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LearningApi
   */
  public setLearning(options?: AxiosRequestConfig) {
    return LearningApiFp(this.configuration)
      .setLearning(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Get summary of given date\'s news from DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the news for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNews: async (
      searchDate?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/news`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (searchDate !== undefined) {
        localVarQueryParameter["search_date"] = searchDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Publish today\'s news summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setNews: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/news`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get summary of given date\'s news from DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the news for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNews(
      searchDate?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<News>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNews(
        searchDate,
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["NewsApi.getNews"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Publish today\'s news summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setNews(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setNews(
        options
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap["NewsApi.setNews"]?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NewsApiFp(configuration);
  return {
    /**
     *
     * @summary Get summary of given date\'s news from DB
     * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the news for. Defaults to latest in the table.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNews(
      searchDate?: string,
      options?: AxiosRequestConfig
    ): AxiosPromise<News> {
      return localVarFp
        .getNews(searchDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Publish today\'s news summary to DB
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setNews(options?: AxiosRequestConfig): AxiosPromise<object> {
      return localVarFp
        .setNews(options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
  /**
   *
   * @summary Get summary of given date\'s news from DB
   * @param {string} [searchDate] The date (in ISO format \&#39;yyyy-mm-dd\&#39;) to receive the news for. Defaults to latest in the table.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public getNews(searchDate?: string, options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .getNews(searchDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Publish today\'s news summary to DB
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NewsApi
   */
  public setNews(options?: AxiosRequestConfig) {
    return NewsApiFp(this.configuration)
      .setNews(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
