// src/Router.tsx
import { Navigate, useRoutes } from "react-router-dom";
import { DashboardLayout } from "./layouts/dashboard/DashboardLayout";
import EmptyLayout from "./layouts/EmptyLayout";
import React, { Suspense, lazy } from "react";
import { useMemo, useState } from "react";
import OpenAiApiClient from "./api/OpenAiApiClient";
import {
  getOpenAiApiKeyLocalStorage,
  setOpenAiApiKeyLocalStorage,
} from "./api/LocalEnvironmentClient";
import AdminPage from "./pages/admin/Admin";

// Lazy-loaded components
const NotFoundPage = lazy(() => import("./pages/404/NotFound"));
const BioPage = lazy(() => import("./pages/bio/Bio"));
const BlogPage = lazy(() => import("./pages/blog/Blog"));
const BlogPost = lazy(() => import("./pages/blog/BlogPost"));
const BooksPage = lazy(() => import("./pages/books/Books"));
const DashboardPage = lazy(() => import("./pages/dashboard/Dashboard"));
const HomePage = lazy(() => import("./pages/home/Home"));
const DailyUpdatePage = lazy(() => import("./pages/daily/DailyUpdatePage"));

export default function Router() {
  const [openAiApiKey, setOpenAiApiKey] = useState<string>("");
  const openAiApi = useMemo(
    () => (openAiApiKey ? new OpenAiApiClient(openAiApiKey) : undefined),
    [openAiApiKey]
  );

  const getOpenAiApiKeyFromEnv = () => {
    if (!openAiApiKey) {
      const key: string = getOpenAiApiKeyLocalStorage();
      if (key && key.trim()) {
        if (
          process.env.REACT_APP_OPENAI_API_KEY_PASS === key &&
          process.env.REACT_APP_OPENAI_API_KEY
        ) {
          setOpenAiApiKey(process.env.REACT_APP_OPENAI_API_KEY);
        } else {
          setOpenAiApiKey(key);
        }
      }
    }
  };

  const resetOpenAiApiKey = () => {
    setOpenAiApiKey("");
    setOpenAiApiKeyLocalStorage("");
  };

  getOpenAiApiKeyFromEnv();
  const isAuthorized =
    getOpenAiApiKeyLocalStorage() === process.env.REACT_APP_OPENAI_API_KEY_PASS;

  const routes = [
    {
      path: "/dashboard",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <DashboardPage
                openAiApi={openAiApi}
                openAiApiFunctions={{
                  openAiApiKey,
                  setOpenAiApiKey,
                  resetOpenAiApiKey,
                }}
              />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/recipes",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <DashboardPage
                openAiApi={openAiApi}
                openAiApiFunctions={{
                  openAiApiKey,
                  setOpenAiApiKey,
                  resetOpenAiApiKey,
                }}
                widgetType={"recipes"}
              />
            </Suspense>
          ),
        },
      ],
    },
    // Add similar Suspense wrappers for other routes (e.g., /generate-image, /itinerary, etc.)
    {
      path: "/books",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <BooksPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/daily",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <DailyUpdatePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/bio",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <BioPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/admin",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <AdminPage isAuthorized={isAuthorized} />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/blog",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <BlogPage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/blog/:id",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <BlogPost />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout isAuthorized={isAuthorized} />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<div />}>
              <HomePage />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/404",
      element: <EmptyLayout />,
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<div />}>
              <NotFoundPage />
            </Suspense>
          ),
        },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(routes);
}
