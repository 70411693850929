import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { Configuration, ImageApi, LearningApi } from "../../api/news";

// Define the API base URL from the OpenAPI spec
const API_BASE_URL =
  "https://gkz1ukftsi.execute-api.us-west-1.amazonaws.com/prod/";

// Alert timeout duration in milliseconds (4 seconds)
const ALERT_TIMEOUT = 4000;

export default function AdminPage({ isAuthorized }: { isAuthorized: boolean }) {
  const [learningTopic, setLearningTopic] = useState("");
  const [learningTopicAlert, setLearningTopicAlert] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);

  const [promptTitle, setPromptTitle] = useState("");
  const [promptText, setPromptText] = useState("");
  const [imagePromptAlert, setImagePromptAlert] = useState<{
    message: string;
    severity: "success" | "error";
  } | null>(null);

  const config = new Configuration({
    basePath: API_BASE_URL,
  });
  const learningApi = new LearningApi(config);
  const imageApi = new ImageApi(config);

  // Function to add a LearningTopic
  const addLearningTopic = async () => {
    if (!learningTopic.trim()) {
      setLearningTopicAlert({
        message: "Please enter a learning topic.",
        severity: "error",
      });
      return;
    }

    try {
      const response = await learningApi.addLearningTopic({
        topic: learningTopic,
      });
      setLearningTopicAlert({
        message: response.data.message || "Learning topic added successfully.",
        severity: "success",
      });
      setLearningTopic(""); // Clear input on success
    } catch (error: any) {
      setLearningTopicAlert({
        message:
          error.response?.data?.message || "Error adding learning topic.",
        severity: "error",
      });
    }
  };

  // Function to add an ImageOfTheDayPrompt
  const addImagePrompt = async () => {
    if (!promptTitle.trim() || !promptText.trim()) {
      setImagePromptAlert({
        message: "Please enter both a title and text.",
        severity: "error",
      });
      return;
    }

    try {
      const response = await imageApi.addImagePrompt({
        title: promptTitle,
        text: promptText,
      });
      setImagePromptAlert({
        message: response.data.message || "Image prompt added successfully.",
        severity: "success",
      });
      setPromptTitle(""); // Clear inputs on success
      setPromptText("");
    } catch (error: any) {
      setImagePromptAlert({
        message: error.response?.data?.message || "Error adding image prompt.",
        severity: "error",
      });
    }
  };

  // Effect to clear learning topic alert after timeout
  useEffect(() => {
    if (learningTopicAlert) {
      const timer = setTimeout(
        () => setLearningTopicAlert(null),
        ALERT_TIMEOUT
      );
      return () => clearTimeout(timer); // Cleanup on unmount or alert change
    }
  }, [learningTopicAlert]);

  // Effect to clear image prompt alert after timeout
  useEffect(() => {
    if (imagePromptAlert) {
      const timer = setTimeout(() => setImagePromptAlert(null), ALERT_TIMEOUT);
      return () => clearTimeout(timer); // Cleanup on unmount or alert change
    }
  }, [imagePromptAlert]);

  // Render unauthorized message if not authorized
  if (!isAuthorized) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 4 }}>
              <Typography variant="h3">Unauthorized</Typography>
              <Typography variant="body1">
                You need administrative access to view this page.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  // Render the admin dashboard
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 4 }}>
            <Typography variant="h3">Admin Dashboard</Typography>
            <Typography variant="body1">
              Manage learning topics and image prompts here.
            </Typography>

            {/* Learning Topic Section */}
            <Typography variant="h5" sx={{ mt: 4 }}>
              Add Learning Topic
            </Typography>
            <TextField
              label="Learning Topic"
              value={learningTopic}
              onChange={(e) => setLearningTopic(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={addLearningTopic}>
              Add Topic
            </Button>
            {learningTopicAlert && (
              <Alert sx={{ mt: 2 }} severity={learningTopicAlert.severity}>
                {learningTopicAlert.message}
              </Alert>
            )}

            {/* Image Prompt Section */}
            <Typography variant="h5" sx={{ mt: 4 }}>
              Add Image Prompt
            </Typography>
            <TextField
              label="Prompt Title"
              value={promptTitle}
              onChange={(e) => setPromptTitle(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Prompt Text"
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
              fullWidth
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
            <Button variant="contained" onClick={addImagePrompt}>
              Add Prompt
            </Button>
            {imagePromptAlert && (
              <Alert sx={{ mt: 2 }} severity={imagePromptAlert.severity}>
                {imagePromptAlert.message}
              </Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
