import { DashboardNavbar } from "./DashboardNavbar";
import { DashboardSidebar } from "./DashboardSidebar";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Box from "@mui/material/Box";

const DashboardLayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  paddingTop: 64,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 280,
  },
}));

const DashboardContent = styled(Box)(() => ({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
}));

interface DashboardLayoutProps {
  isAuthorized: boolean; // Add this prop
}

export const DashboardLayout = ({ isAuthorized }: DashboardLayoutProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar setIsSidebarOpen={setIsSidebarOpen} />
      <DashboardSidebar
        setIsSidebarOpen={setIsSidebarOpen}
        isOpen={isSidebarOpen}
        isAuthorized={isAuthorized}
      />
      <DashboardContent>
        <Outlet />
      </DashboardContent>
    </DashboardLayoutRoot>
  );
};
